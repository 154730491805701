<template>
  <div>
    <div class="tw-flex tw-flex-wrap">
      <v-col cols="12" md="6">
        <label class="text-headings">Salary</label>
        <v-combobox
          class="mt-4"
          :items="salaries"
          hide-details
          placeholder="150,000"
          solo
          v-model="paddlerData.salary"
        >
          <template #append>
            <icons name="select-dropdown" />
          </template>
          <template #prepend-inner>
            <icons name="naira" />
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="12" md="6">
        <label class="text-headings">Bank Name</label>
        <v-combobox v-if="paddlerData.accountDetail"
          class="mt-4"
          :items="banks"
          hide-details
          placeholder="GTbank"
          solo
          item-value="bankName" item-text="bankName"
          v-model="paddlerData.accountDetail.bankName"
        >
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-combobox>
      </v-col>
    </div>
    <div class="tw-flex tw-flex-wrap">
      <v-col md="6" cols="12">
        <label class="text-headings">Account Type</label>
        <v-combobox v-if="paddlerData.accountDetail"
          class="mt-4"
          placeholder="Savings"
          hide-details
          :items="accountTypes"
          solo
          v-model="paddlerData.accountDetail.accountType"

        >
          <template #append>
            <icons name="select-dropdown" />
          </template>
        </v-combobox>
      </v-col>
      <v-col md="6" cols="12">
        <label class="text-headings">Account Number</label>
        <v-text-field v-if="paddlerData.accountDetail"
          class="mt-4"
          placeholder="0021232345"
          hide-details
          solo
           type="Number"
          v-model="paddlerData.accountDetail.accountNumber"
        ></v-text-field>
      </v-col>
    </div>
    <div class="tw-flex tw-flex-wrap">
      <v-col md="6" cols="12">
        <label class="text-headings">Account Name</label>
        <v-text-field
            v-if="paddlerData.accountDetail"
            class="mt-4"
            placeholder="Stephanie Okon"
            hide-details
            solo
            v-model="paddlerData.accountDetail.accountName"
        ></v-text-field>
      </v-col>
    </div>
  </div>
</template>

<script>
import Icons from "../../../components/reuseables/Icons.vue";
import {mapGetters} from "vuex";
import {getAllBanks, getKudaAuthToken} from "@/services/api/APIService";
export default {
  name: "Step6",
  components: { Icons },
  props: {

  },
  data(){
    return{
      banks:[],
      salaries:["50,000","100,000","150,000","200,000","250,000","300,000","350,000","400,000","450,000","500,000"],
      accountTypes:["Corporate","Current","Savings"],
    }
  },
  computed:{
    ...mapGetters("paddler",["paddlerData"])
  },
  methods:{
    async getAllNgBanks(){
      let kudaLoginData = {}
      this.banks = []
      kudaLoginData.email = process.env.VUE_APP_KUDAEMAIL
      kudaLoginData.apiKey= process.env.VUE_APP_KUDAAPIKEY
      await getKudaAuthToken(kudaLoginData).then(async res => {
        let data = {}
        let header = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+res.data,
        }
        data.data= "{\"serviceType\": \"BANK_LIST\",\"requestRef\": \"123456789\"}"
        await getAllBanks(data,header).then(res => {
          const objectJson = JSON.parse(res.data.data)
          objectJson.Data.banks.forEach(bank => {
            let newBank = {}
            newBank.bankName = bank.BankName
            newBank.bankCode = bank.BankCode
            this.banks.push(newBank)
          })

        }).catch(() => {})
      })

    }
  },
  async created() {
    await this.getAllNgBanks()
  }
};
</script>

<style></style>
